exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[id]-tsx": () => import("./../../../src/pages/[id].tsx" /* webpackChunkName: "component---src-pages-[id]-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-registration-cancel-tsx": () => import("./../../../src/pages/registration/cancel.tsx" /* webpackChunkName: "component---src-pages-registration-cancel-tsx" */),
  "component---src-pages-registration-confirm-tsx": () => import("./../../../src/pages/registration/confirm.tsx" /* webpackChunkName: "component---src-pages-registration-confirm-tsx" */),
  "component---src-pages-registration-finish-tsx": () => import("./../../../src/pages/registration/finish.tsx" /* webpackChunkName: "component---src-pages-registration-finish-tsx" */),
  "component---src-pages-registration-ready-tsx": () => import("./../../../src/pages/registration/ready.tsx" /* webpackChunkName: "component---src-pages-registration-ready-tsx" */),
  "component---src-pages-start-[id]-tsx": () => import("./../../../src/pages/start/[id].tsx" /* webpackChunkName: "component---src-pages-start-[id]-tsx" */)
}

